// ----- Modules ----- //
import React, { useEffect, useState } from 'react';

// ----- MUI ----- //
import { Box, Divider, Modal, Typography } from '@mui/material';
import TitleIcon from '@mui/icons-material/AutoStories';
import AwesomeIcon from '@mui/icons-material/AutoAwesome';
import WarningIcon from '@mui/icons-material/WarningAmber';

// ----- Components ----- //
import Title from "./components/Title";
import { FONTS } from "../../index";

// ----- Utils ----- //
import { ModalStyle } from '../../Utils/Theme/Theme';

// ----- Constants ----- //
const NEWS_KEY = 'HasSeenNews-FixedPasteInAccountEdit';
const TITLE = 'It\'s time for a fix!';
const HAS_DRAWBACKS = false;


const News = () => {
  // Initialize the firstTime state based on localStorage value
  const [firstTime, setFirstTime] = useState(
    localStorage.getItem(NEWS_KEY) !== 'true'
  );

  const handleClose = () => {
    setFirstTime(false);
    // Set the flag in localStorage when the modal is closed
    localStorage.setItem(NEWS_KEY, 'true');
  };

  // Effect to handle the case when the user has seen the modal but the component is re-mounted
  useEffect(() => {
    if (localStorage.getItem(NEWS_KEY) === 'true') {
      setFirstTime(false);
    }
  }, []);

  return (
    <Modal
      open={firstTime}
      onClose={handleClose}
      style={{backdropFilter: 'blur(2px)'}}
    >
      <Box sx={{...ModalStyle, width: '40%'}}>

        <Title title={TITLE} icon={<TitleIcon sx={{fontSize: '35px'}}/>}
               close={handleClose}/>

        <Divider/>

        <Typography variant="h5"
                    fontFamily={FONTS}
                    sx={{marginTop: '1em', lineHeight: '1.5', display: 'flex', alignItems: 'center', gap: '0.25em'}}>
          <AwesomeIcon sx={{fontSize: '25px'}}/> What's new
        </Typography>

        <Typography variant="body1" component="div" style={{marginTop: '1em', lineHeight: '1.5'}}>
          <ul>
            <li><strong>Account edit: </strong>
              You can now paste text in the Account Edit modal by using the standard keyboard shortcut (Ctrl/Cmd + V).
            </li>
          </ul>
        </Typography>

        {HAS_DRAWBACKS && (
          <>
            <Divider sx={{marginTop: '1em'}}/><Typography variant="h5"
                                                          fontFamily={FONTS}
                                                          sx={{
                                                            marginTop: '1em',
                                                            lineHeight: '1.5',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: '0.25em'
                                                          }}>
            <WarningIcon sx={{fontSize: '25px'}}/> Considerations
          </Typography><Typography variant="body1" component="div" style={{marginTop: '1em', lineHeight: '1.5'}}>
            <Typography color="text.secondary">
              While the update brings significant improvements, there may be some considerations to keep in mind:
            </Typography>
            <ul>
              <li><strong>Credit cards:</strong> They can't be edited anymore. If you need to change the credit card
                info, please contact us.
              </li>
            </ul>
          </Typography>
          </>
        )}

        If you have any questions or concerns, please don't hesitate to reach out to us.

      </Box>
    </Modal>
  );
};

export default News;
