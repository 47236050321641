// ----- Modules ----- //
import React, { useContext, useMemo } from "react";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

// ----- MUI ----- //
import {
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  gridExpandedSortedRowIdsSelector,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  useGridApiContext
} from "@mui/x-data-grid-premium";
import { Box, ButtonBase, Chip, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
// Icons
import NoSyncIcon from "@mui/icons-material/HorizontalRule";
import CantBeUsed from "@mui/icons-material/HorizontalRule";
import WarningIcon from "@mui/icons-material/WarningAmber";
import SyncIcon from '@mui/icons-material/CloudSync';
import EditIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import BusyIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import NotBusyIcon from '@mui/icons-material/CheckCircleOutlineOutlined';


// ----- Components ----- //
import Tab, { handleColumnsSelector } from "./Tab";
import CreditCard from "../../components/modal/CreditCard";
import Account from "../../components/modal/Account";
import ActionBtns from "../../components/ActionBtns";
import CellCopy from "../../components/datagrid/CellCopy";

// ----- Utils ----- //
import { AccountsContext } from "../../contexts/AccountsProvider";
import { Pages } from "../../Utils/Types";
import { getSelectedTheme } from "../../Utils/Colors";
import { FONTS } from "../../index";
import { StatusContext } from "../../contexts/StatusProvider";
import CellDateTime from "../../components/datagrid/CellDateTime";

const getFilteredRows = ({apiRef}: GridCsvGetRowsToExportParams) =>
  gridExpandedSortedRowIdsSelector(apiRef);

function CustomToolbar() {
  const apiRef = useGridApiContext();

  // ----- Functions ----- //
  const handleExport = (options: GridCsvExportOptions) => {
    try {
      if (apiRef.current.getRowsCount() === 0)
        enqueueSnackbar('No accounts to export.', {variant: 'warning'});
      else {
        const date = moment().format('YYYY-MM-DD');
        options.fileName = `accounts-filtered_${date}.csv`;
        apiRef.current.exportDataAsCsv(options);
        enqueueSnackbar('Accounts exported successfully!', {variant: 'success'});
      }
    } catch (e) {
      enqueueSnackbar('Failed to export accounts.', {variant: 'error'});
    }
  };

  // ----- Render ----- //
  return (
    <Box sx={{py: 1.5}}>
      <Box sx={{display: 'none'}} id={'column-visibility'}><GridToolbarColumnsButton/></Box>
      <ActionBtns
        tab={Pages.accounts}
        SelectColumns={handleColumnsSelector}
        exportElements={() => handleExport({getRowsToExport: getFilteredRows})}
        CreateElement={
          <>
            <Account edit={false} account={null}/>
            {/*<RequestAccounts/>*/}
          </>
        }
        BulkActionsElement={<BulkActions/>}
      />
    </Box>
  );
}

const columns = (onEditClick: (account: any) => void) => [
  {
    field: 'id', headerName: 'ID', width: 100, renderCell: (params: any) =>
      <CellCopy value={params.value}/>
  },
  {
    field: '*Edit Account*', headerName: '', minWidth: 0, width: 40,
    filterable: false, sortable: false, disableColumnMenu: true,
    headerAlign: 'center', align: 'center',
    renderHeader: () =>
      <Tooltip title={'Edit Account'} placement={'top'} disableInteractive><EditIcon
        sx={{color: getSelectedTheme().darkText}}/></Tooltip>,
    renderCell: (params: any) => (
      <IconButton
        onClick={() => onEditClick(params.row)}
        aria-label="Edit Account"
      >
        <EditIcon sx={{color: getSelectedTheme().accent}}/>
      </IconButton>
    ),
  },
  {
    field: '*Credit Card*', headerName: '', minWidth: 0, width: 40,
    sortable: false, disableColumnMenu: true,
    headerAlign: 'center', align: 'center', type: "singleSelect",
    renderHeader: () =>
      <Tooltip title={'View Credit Card'} placement={'top'} disableInteractive><CreditCardIcon
        sx={{color: getSelectedTheme().darkText}}/></Tooltip>,
    renderCell: (params: any) => <CreditCard account_id={params.row.id} cc_info={params.row.cc_info}
                                             cc2_info={params.row.cc2_info}/>,
    filterable: false
  },
  {
    field: 'lists', headerName: 'Lists', width: 100,
    valueGetter: (value: any) => value?.map((list: any) => list.name).join(', '),
    sortable: false,
  },
  {
    field: 'email', headerName: 'Email', width: 200, renderCell: (params: any) =>
      <CellCopy value={params.value}/>
  },
  {
    field: 'password', headerName: 'tm pwd', width: 130, renderCell: (params: any) =>
      <CellCopy value={params.value}/>
  },
  {
    field: 'axs_password', headerName: 'axs pwd', width: 130,
    valueGetter: (_: never, row: any) => row.axs_account?.password,
    renderCell: (params: any) => <CellCopy value={params.value}/>,
    filterable: false,
    sortable: false
  },
  {
    field: 'in_use', headerName: 'In Use', width: 75, type: 'boolean', sortable: false, disableColumnMenu: true,
    valueGetter: (_: never, row: any) => !row.in_use || row.last_in_use > moment().subtract(1, 'minutes').toISOString(),
    renderHeader: () => <Tooltip title={'If the account is currently in use'} placement={'top'} disableInteractive>
      <NotBusyIcon sx={{color: getSelectedTheme().darkText}}/>
    </Tooltip>,
    renderCell: (params: any) =>
      params.row.status.id != 1 ?
        <Tooltip title={'This account cannot be used'} placement={'top'} disableInteractive>
          <CantBeUsed sx={{color: getSelectedTheme().darkText}}/>
        </Tooltip> :
        params.row.in_use ?
          <Tooltip title={params.row.in_use_by ? `In use by ${params.row.in_use_by}` : 'In use'} placement={'top'}
                   disableInteractive arrow>
            <BusyIcon sx={{color: getSelectedTheme().error}}/>
          </Tooltip> :
          <NotBusyIcon sx={{color: getSelectedTheme().success}}/>
  },
  {
    field: 'status', headerName: 'Status', width: 130, type: "singleSelect",
    valueOptions: [],
    valueGetter: (_: never, row: any) => row.status.name,
    renderCell: (params: any) =>
      <Tooltip title={params.row.status.description} placement={'top'} disableInteractive>
        <Chip label={params.row.status.name} size={'small'}
              sx={{backgroundColor: params.row.status.color, fontWeight: 'bold', color: 'white'}}/>
      </Tooltip>,
    sortable: false
  },
  {
    field: 'is_ds', headerName: 'TYPE', width: 75, sortable: false, disableColumnMenu: true,
    valueGetter: (_: never, row: any) => row.lists.some((list: any) => list.is_ds) ? 'DS' : 'LONG',
  },
  {
    field: 'manager sync', headerName: '1Ticket', width: 75,
    type: "singleSelect", sortable: false, disableColumnMenu: true,
    headerAlign: "center", align: "center",
    valueOptions: ["Never Sync ⎻", "Attention Required ⚠", "Synced ✓", "Not Synced ×"],
    renderCell: (params: any) => {
      switch (true) {
        case params.row.lists?.some((list: any) => !list.sync):
          return <Tooltip title={"Never Sync"} placement={'top'} disableInteractive><NoSyncIcon
            sx={{color: getSelectedTheme().darkText}}/></Tooltip>;
        case params.row.manager_status?.length > 0 && params.row.manager_synced:
          return <Tooltip title={params.row.manager_status} placement={'top'} disableInteractive><WarningIcon
            sx={{color: getSelectedTheme().warning}}/></Tooltip>;
        case params.row.manager_synced:
          return <Tooltip title={"Synced"} placement={'top'} disableInteractive><SyncIcon
            sx={{color: getSelectedTheme().success}}/></Tooltip>;
        default:
          return <Tooltip title={"Not Synced"} placement={'top'} disableInteractive><SyncIcon
            sx={{color: getSelectedTheme().darkText}}/></Tooltip>;
      }
    },
    valueGetter: (_: never, row: any) => {
      switch (true) {
        case row.lists?.some((list: any) => !list.sync):
          return "Never Sync";
        case row.manager_status?.length > 0 && row.manager_synced:
          return "Attention Required";
        case row.manager_synced:
          return "Synced";
        default:
          return "Not Synced";
      }
    },
    filterable: false
  },
  {field: 'manager_status', headerName: '1Ticket Status', width: 100},
  {
    field: 'name', headerName: 'Name', width: 130, renderCell: (params: any) =>
      <CellCopy value={params.value}/>
  },
  {
    field: 'phone', headerName: 'Phone', width: 130, renderCell: (params: any) =>
      <Box sx={params.row?.phone_id ? {color: getSelectedTheme().success} : {}}>
        <CellCopy value={params.value}/>
      </Box>
  },
  {field: 'address', headerName: 'Address', width: 130},
  {field: 'city', headerName: 'City', width: 130},
  {field: 'zip', headerName: 'Zip Code', width: 100},
  {field: 'state', headerName: 'State', width: 75},
  {
    field: 'country', headerName: 'Country', width: 100,
    valueFormatter: (value: any) => value ? getUnicodeFlagIcon(value) : '',
    sortable: false
  },
  {field: 'comment', headerName: 'Comment', width: 250},
  {
    field: 'updated_at', headerName: 'Updated At', width: 130, align: 'right',
    renderCell: (params: GridRenderCellParams<any, Date>) =>
      <CellDateTime value={params.row.updated_at !== params.row.created_at ? params.row.updated_at : null}/>,
    filterable: false
  },
  {
    field: 'created_at', headerName: 'Created At', width: 130, align: 'right',
    renderCell: (params: any) => <CellDateTime value={params.value}/>,
    filterable: false
  }
];

/**
 * Accounts Page component. Displays a list of accounts.
 */
const Accounts = () => {
  // ----- Context ----- //
  const {status} = useContext(StatusContext);

  // ----- States ----- //
  const [isEditing, setIsEditing] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState(null);

  const handleEditClick = (account: any) => {
    setSelectedAccount(account);
    setIsEditing(true);
  };

  const closeModal = () => {
    setSelectedAccount(null);
    setIsEditing(false);
  };

  const updatedColumns = useMemo(() => {
    return columns(handleEditClick).map((column) => {
      if (column.field === 'status') {
        return {
          ...column,
          valueOptions: status.map(s => s.name),
        };
      }
      return column;
    });
  }, [status]);

  return (
    <>
      <Tab columns={updatedColumns} toolbar={CustomToolbar} context={AccountsContext}/>
      {isEditing && (
        <Account
          edit={true}
          account={selectedAccount}
          onClose={closeModal}
        />
      )}
    </>
  );
};

const BulkActions = () => {
  const apiRef = useGridApiContext();

  const {fetchData} = useContext(AccountsContext);

  const [toSync, setToSync] = React.useState(0);
  const [synced, setSynced] = React.useState(0);
  const [syncing, setSyncing] = React.useState(false);

  const handleSync = async () => {
    setToSync(Array.from(apiRef.current.getSelectedRows().values()).length);
    setSynced(0);

    let count = 0;

    setSyncing(true);
    const selected = Array.from(apiRef.current.getSelectedRows().values());
    for (const account of selected) {
      try {
        const synced = await syncWithManager(account.email, account.password);
        if (synced) {
          console.log('Synced!', account.email);
          setSynced((prev) => prev + 1);
          count++;
        }
      } catch (error) {
        console.error('Sync failed for', account.email, error);
      }
    }

    console.log('Synced', count, 'accounts');

    count === 0 ? enqueueSnackbar(`Failed to sync any accounts. Please try again later.`, {variant: 'error'}) :
      enqueueSnackbar(`Successfully synced ${count} accounts!`, {variant: 'success'});

    fetchData();
    setSyncing(false);
  };

  return (
    <Box sx={{display: 'flex', alignItems: 'center', height: '100%', position: 'relative', left: '-8px'}}>

      <Tooltip
        title={'Sync or re-sync selected accounts with 1Ticket. Only accounts with a valid list will be synced.'}>
        <ButtonBase
          onClick={!syncing ? () => handleSync() : undefined}
          disabled={Array.from(apiRef.current.getSelectedRows().values()).length === 0}
          sx={{display: 'flex', alignItems: 'center', height: '100%', pr: 0.5}}
        >
          {!syncing ? (
            <IconButton aria-label="Sync Accounts" sx={{p: 0.5}}
                        component="span">
              <SyncIcon sx={{
                color: Array.from(apiRef.current.getSelectedRows().values()).length === 0 ? getSelectedTheme().darkText : getSelectedTheme().accent,
                fontSize: '40px', position: 'relative', left: '-3px'
              }}/>
            </IconButton>
          ) : (
            <CircularProgress sx={{color: getSelectedTheme().accent, mx: 1}} size={30}/>
          )}

          <Typography sx={{whiteSpace: 'nowrap', mt: 1.4}} fontFamily={FONTS}
                      fontSize={20}>
            Sync {Array.from(apiRef.current.getSelectedRows().values()).length} Accounts {toSync > 0 && syncing && `(${synced}/${toSync})`}
          </Typography>
        </ButtonBase>
      </Tooltip>
    </Box>
  );
};

const syncWithManager = async (email: string, password: string): Promise<boolean> => {
  return await axios.post('/api/account-manager/email', {
    email,
    password,
  }, {withCredentials: true})
    .then(() => {
      return true;
    }).catch(() => {
      enqueueSnackbar(`Failed to sync ${email}.`, {variant: 'warning'});
      return false;
    });
};

export default Accounts;
