// ----- Module ----- //
import React, { useCallback, useContext, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useConfiguredAxios } from "../../Utils/AxiosInstance";

// ----- MUI ----- //
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
  TextField,
  Typography
} from "@mui/material";
// Icons
import EditIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import UserIcon from '@mui/icons-material/PersonOutlineOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import CommentIcon from '@mui/icons-material/CommentOutlined';
import ListIcon from '@mui/icons-material/FormatListBulleted';
import CreateAccountIcon from '@mui/icons-material/GroupAddOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ProxyIcon from '@mui/icons-material/LanOutlined';

// ----- Components ----- //
import ListsSelect from "../inputs/ListsSelect";
import Title from "./components/Title";

// ----- Utils ----- //
import { AccountT, ListT, ProxyT } from "../../Utils/Types";
import { AccountsContext } from "../../contexts/AccountsProvider";
import { ProxiesContext } from "../../contexts/ProxiesProvider";
import axios from "axios";
import { getSelectedTheme } from "../../Utils/Colors";
import { ModalStyle } from "../../Utils/Theme/Theme";
import { StatusContext } from "../../contexts/StatusProvider";
import { FONTS } from "../../index";
import StatusSelect from "../inputs/StatusSelect";

/**
 * Modal for editing or creating an account
 * @param props{{edit: boolean, account: AccountT | null}}
 * - edit: whether the modal is for editing or creating an account
 * - account: the account to edit, if edit is true
 */
const Account = (props: { edit: boolean, account: AccountT | null, onClose?: () => void }) => {
  const axiosInstance = useConfiguredAxios();

  // ----- Context ----- //
  const {handleEdit, handleCreate, handleDelete} = useContext(AccountsContext);
  const {getAvailableProxies} = useContext(ProxiesContext);
  const {status} = useContext(StatusContext);
  const {enqueueSnackbar} = useSnackbar();

  // ----- Props ----- //
  const {edit, account, onClose} = props;

  // ----- States ----- //
  const [accountInfo, setAccountInfo] = React.useState<AccountT>(account || {} as AccountT);

  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [deleteAccount, setDeleteAccount] = React.useState(false);

  const [blockSync, setBlockSync] = React.useState(accountInfo?.lists?.some((list: ListT) => !list.sync));

  const [unSyncDialog, setUnSyncDialog] = React.useState(false);

  const [availableProxies, setAvailableProxies] = React.useState<ProxyT[] | null>(null);
  const [proxiesLoading, setProxiesLoading] = React.useState(false);
  const [hasInteracted, setHasInteracted] = React.useState(false);

  useEffect(() => {
    setOpen(!!(edit && account));
  }, [edit, account]);

  // ----- Functions ----- //
  const handleOpen = () => {
    setAccountInfo(account || {} as AccountT);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAccountInfo(account || {} as AccountT);
    setDeleteAccount(false);

    onClose && onClose();
  };

  const handleAccountInfoChange = (key: string, value: string | number | {} | null) => {
    if (!accountInfo) return;
    setAccountInfo(prevAccountInfo => ({...prevAccountInfo, [key]: value}));
  };

  const syncWithManager = async () => {
    return await axios.post('/api/account-manager/email', {
      email: accountInfo.email,
      password: accountInfo.password
    }, {withCredentials: true})
      .then(() => {
        enqueueSnackbar('Successfully synced with 1Ticket.', {variant: 'success'});
        handleAccountInfoChange('manager_synced', true);
        return true;
      })
      .catch(() => {
        enqueueSnackbar('Failed to sync with 1Ticket.', {variant: 'error'});
        handleAccountInfoChange('manager_synced', false);
        return false;
      });
  };

  /**
   * Handles the submission of the form
   * - If edit is true, it will edit the account
   * - If edit is false, it will create the account
   * - If deleteAccount is true, it will delete the account
   */
  const handleSubmission = useCallback(() => {
    setIsLoading(true);

    switch (true) {
      case edit && deleteAccount:
        axiosInstance.delete(`/api/accounts/${accountInfo?.id}`)
          .then(res => handleResponse(res, () => handleDelete(accountInfo?.id), undefined))
          .catch(handleError);
        break;

      case edit:
        axiosInstance.put(`/api/accounts/${accountInfo?.id}`, accountInfo, {withCredentials: true})
          .then(async res => {
            let isSynced;
            if (accountInfo?.manager_synced)
              isSynced = await syncWithManager();

            handleResponse(res, handleEdit, isSynced);
          })
          .catch(handleError);
        break;

      default:
        axiosInstance.post('/api/accounts/', accountInfo, {withCredentials: true})
          .then(async res => {
            let isSynced;
            if (accountInfo?.manager_synced)
              isSynced = await syncWithManager();

            handleResponse(res, handleCreate, isSynced);
          })
          .catch(handleError);

        break;
    }
  }, [accountInfo, edit, deleteAccount]);

  function handleResponse(res: any, successCallback: any, managerSynced: boolean | undefined) {
    if (!res.data) return;
    setIsLoading(false);

    if (managerSynced != undefined)
      res.data.manager_synced = managerSynced;

    successCallback(res.data);

    enqueueSnackbar(`Account ${deleteAccount ? 'deleted' : edit ? 'updated' : 'created'} successfully!`,
      {variant: 'success'});

    handleClose();
  }

  function handleError() {
    setIsLoading(false);
  }

  async function handleProxyFocus() {
    if (!hasInteracted) {
      setHasInteracted(true);

      let listName;
      if (accountInfo?.lists?.some((list: ListT) => list.name.toLowerCase() === 'headless'))
        listName = 'headless';
      else if (accountInfo?.lists?.some((list: ListT) => list.name.toLowerCase() === 'pippen'))
        listName = 'pippen';
      else if (accountInfo?.lists?.some((list: ListT) => list.name.toLowerCase() === 'curry'))
        listName = 'curry';

      setProxiesLoading(true);
      const result = await getAvailableProxies(listName);
      setAvailableProxies(result);
      setProxiesLoading(false);
    }
  }

  // ----- Render ----- //
  return (
    <>
      {!edit &&
        <IconButton aria-label="Show Accounts Info" component="span"
                    sx={{p: 0.25, position: 'relative', left: '-7px'}}
                    onClick={handleOpen}>
          <CreateAccountIcon sx={{color: getSelectedTheme().accent, fontSize: '40px'}}/>
        </IconButton>
      }

      <Modal
        open={open}
        onClose={handleClose}
        style={{backdropFilter: "blur(2px)"}}
      >
        <Box sx={ModalStyle}>
          <Title title={(edit ? 'EDIT' : 'ADD') + ' ACCOUNT'} icon={<EditIcon sx={{fontSize: '35px'}}/>}/>

          <Divider/>

          <Box sx={{py: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3}}>
            <Box sx={{display: 'flex', gap: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
              <UserIcon sx={{fontSize: '35px'}}/>
              <TextField id="account-name" label="Name" variant="outlined" size={'small'}
                         InputLabelProps={{
                           style: {
                             fontFamily: FONTS,
                             fontSize: '20px'
                           }
                         }}
                         defaultValue={account ? account.name : ''}
                         onChange={(e) => handleAccountInfoChange('name', e.target.value)}/>
              <TextField id="account-phone" label="Phone" variant="outlined" size={'small'}
                         defaultValue={account ? account.phone : ''}
                         onChange={(e) => {
                           handleAccountInfoChange('phone', e.target.value);
                         }}/>
              <TextField id="account-email" label="Email" variant="outlined"
                         size={'small'} required
                         defaultValue={account ? account.email : ''}
                         onChange={(e) => handleAccountInfoChange('email', e.target.value)}/>
              <TextField id="account-password" label="Password" variant="outlined"
                         size={'small'} required
                         defaultValue={account ? account.password : ''}
                         onChange={(e) => handleAccountInfoChange('password', e.target.value)}/>
            </Box>

            <Box sx={{display: 'flex', gap: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
              <HomeIcon sx={{fontSize: '35px'}}/>
              <TextField id="account-address" label="Address" variant="outlined" size={'small'}
                         defaultValue={account ? account.address : ''}
                         onChange={(e) => handleAccountInfoChange('address', e.target.value)}/>
              <TextField id="account-city" label="City" variant="outlined" size={'small'}
                         defaultValue={account ? account.city : ''}
                         onChange={(e) => handleAccountInfoChange('city', e.target.value)}/>
              <TextField id="account-state" label="State" variant="outlined" size={'small'}
                         defaultValue={account ? account.state : ''}
                         sx={{width: '100px'}}
                         onChange={(e) => handleAccountInfoChange('state', e.target.value)}/>
              <TextField id="account-zip" label="Zip" variant="outlined" size={'small'}
                         defaultValue={account ? account.zip : ''}
                         sx={{width: '125px'}}
                         onChange={(e) => handleAccountInfoChange('zip', e.target.value)}/>
              <TextField id="account-country" label="Country" variant="outlined" size={'small'}
                         defaultValue={account ? account.country : ''}
                         sx={{width: '150px'}}
                         onChange={(e) => handleAccountInfoChange('country', e.target.value)}/>
            </Box>

            <Box sx={{display: 'flex', gap: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
              <ListIcon sx={{fontSize: '35px'}}/>
              <ListsSelect
                default_lists={account ? account.lists : []}
                onChange={
                  (_: any, value: ListT[]) => {
                    const dontSync = value.some((list: ListT) => !list.sync);
                    if (account?.manager_synced && dontSync) return;

                    handleAccountInfoChange('lists', value.reduce((acc: ListT[], list: ListT) => {
                      if (acc && acc.some((accList: ListT) => accList.id === list.id)) return acc;
                      return [...acc, list];
                    }, []));

                    if (value.length > 0) {
                      handleAccountInfoChange('manager_synced', value.every((list: ListT) => list.sync));
                      setBlockSync(dontSync);
                    } else if (!account?.manager_synced) {
                      handleAccountInfoChange('manager_synced', false);
                      setBlockSync(false);
                    }
                  }
                }/>

              <Box sx={{whiteSpace: 'nowrap', ml: 1}}>
                <FormControlLabel
                  control={
                    <Switch checked={accountInfo?.manager_synced}
                            disabled={blockSync}
                            onChange={(e: any) => {
                              if (account?.manager_synced && !e.target.checked)
                                setUnSyncDialog(true);
                              else
                                handleAccountInfoChange('manager_synced', e.target.checked);
                            }}
                            name="manager_synced"
                    />
                  }
                  label="Sync 1Ticket"
                />

                <Dialog
                  open={unSyncDialog}
                  onClose={() => setUnSyncDialog(false)}
                  aria-labelledby="un-sync-dialog-title"
                  aria-describedby="un-sync-dialog-description"
                >
                  <DialogTitle id="un-sync-dialog-title">
                    Stop syncing with 1Ticket?
                    <Divider/>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="un-sync-dialog-description">
                      By doing this, the passwords changes won't be synced anymore.
                      <br/>
                      <strong>
                        This account needs to be deleted from 1Ticket manually.
                      </strong>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setUnSyncDialog(false)} autoFocus>Cancel</Button>
                    <Button onClick={() => {
                      setUnSyncDialog(false);
                      handleAccountInfoChange('manager_synced', false);
                    }} variant={'contained'} sx={{backgroundColor: getSelectedTheme().accent, color: 'white'}}>
                      Agree
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Box>

            {accountInfo?.lists?.some((list: ListT) => list.use_proxy) &&
              <Box sx={{display: 'flex', gap: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                <ProxyIcon sx={{fontSize: '35px'}}/>
                <Autocomplete
                  id="account-proxy"
                  options={availableProxies || []}
                  size={'small'}
                  fullWidth
                  getOptionLabel={(option: any) => option.ip}
                  renderInput={(params) => <TextField {...params} label="Proxy" variant="outlined" size={'small'}/>}
                  onChange={(e, value) => handleAccountInfoChange('proxy_id', value?.id || null)}
                  onFocus={handleProxyFocus}
                  defaultValue={account?.proxy || null}
                  noOptionsText={proxiesLoading ? 'Loading...' : 'No proxies available'}
                />
                {infoText(account?.proxy?.port.toString() || '---', 'PORT')}
                {infoText(account?.proxy?.user || '---', 'USER')}
                {infoText(account?.proxy?.pwd || '---', 'PWD')}
              </Box>
            }

            <Box sx={{display: 'flex', gap: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
              <SettingsIcon sx={{fontSize: '35px'}}/>

              <StatusSelect
                default_status={account ? status.find((stat: any) => stat.id === account?.status_id) || null : null}
                onChange={(_: any, value: any) => handleAccountInfoChange('status_id', value?.id || null)}
              />
            </Box>

            <Box sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative'
            }}>
              <Box sx={{display: 'flex', gap: 1, width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                <CommentIcon sx={{fontSize: '35px'}}/>
                <TextField id="account-comment" label="Comment" variant="outlined" size={'small'} fullWidth
                           defaultValue={account ? account.comment : ''}
                           onChange={(e) => handleAccountInfoChange('comment', e.target.value)}
                />
              </Box>

              {accountInfo?.status_id && accountInfo?.status_id !== 1 && !accountInfo?.comment && (
                <Typography variant={'caption'} color={getSelectedTheme().warning} fontWeight={'bold'}
                            sx={{position: 'absolute', bottom: '-18px'}}>
                  Please add a comment to this account to explain why it's not available.
                </Typography>
              )}
            </Box>
          </Box>

          <Divider/>

          <Box sx={{pt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            {edit && (
              <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                <DeleteIcon sx={{fontSize: '35px', color: getSelectedTheme().error}}/>

                <TextField id="account-delete" label="Account's Email" variant="outlined" size={'small'}
                           onChange={(e) =>
                             setDeleteAccount(e.target.value === account?.email)
                           }/>

                {deleteAccount && (
                  <Typography variant={'caption'} color={getSelectedTheme().error}
                              sx={{marginY: 'auto', lineHeight: '1'}}>
                    This account will be deleted permanently!
                  </Typography>
                )}
              </Box>
            )}

            <Box sx={{display: 'flex', gap: 1, alignItems: 'center', marginLeft: 'auto'}}>
              <Button variant="text" sx={{color: getSelectedTheme().accent}}
                      onClick={handleClose}>Cancel</Button>
              <Button variant="contained"
                      sx={{
                        backgroundColor: deleteAccount ? getSelectedTheme().error : getSelectedTheme().accent,
                        color: 'white'
                      }}
                      disabled={isLoading || !accountInfo.email || !accountInfo.password}
                      onClick={handleSubmission}>

                {isLoading ? (
                  <Box sx={{display: 'flex', alignItems: 'center', py: 0.685}}>
                    <CircularProgress size={24} sx={{color: 'white'}}/>
                  </Box>
                ) : (
                  deleteAccount ? 'Delete' :
                    edit ? 'Save' : 'Add'
                )}

              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const infoText = (text: string, header: string) => (
  <Box sx={{ml: 2}}>
    <Typography fontFamily={FONTS}
                sx={{
                  position: 'absolute',
                  fontSize: '14px',
                  color: getSelectedTheme().darkText,
                  zIndex: -1,
                  transform: 'translate(-20%, -20%)',
                  opacity: 0.5
                }}>
      {header}
    </Typography>

    <Typography variant={'subtitle2'} sx={{whiteSpace: 'nowrap', fontSize: '20px'}}
                color={getSelectedTheme().darkText}>
      {text}
    </Typography>
  </Box>
);

export default Account;
